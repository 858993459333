.App {
  min-height: 100vh;
  height: auto;
  background-color: #000000;
  text-align: center;
  color: rgb(255, 255, 255);
}

p {
  margin: 0;
}

.container {
  height: 100%;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-container {
  padding-top: 30px;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 10px 10px 10px;
}

.title {
  margin: 0;
  font-size: 35px;
  font-weight: bold;
  color: white;
}

.subtitle {
  font-size: 25px;
  color: white;
}

.left {
  text-align: left;
  margin-left: 10%;
}

.logo {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.right {
  display: flex;
  background: #303030;
  border-radius: 15px;
  padding: 12px 20px;
  height: 99%;
  margin-right: 10%;
  margin-top: 10px;
}

.content-container {
  background-color: #03CF9E;
}

.cta-button {
  height: 45px;
  border: 0;
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 15px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.connect-wallet-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 550px;
}

.connect-wallet-container img {
  padding-bottom: 20px;
}

.connect-wallet-button {
  background-image: linear-gradient(to right,
      #03CF9E 0%,
      #03CF9E 0%,
      #05a882 21%,
      #008163 52%,
      #03c597 78%,
      #0bba91 100%);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}

.mint-button {
  background: -webkit-linear-gradient(left, #03CF9E, #05a882);
  font-size: 18px;
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  margin: 20px 10px;
  border-radius: 15px;
  padding: 0px 2rem;
}

.mint-button:disabled {
  background: #03CF9E;
  color: #ffffff;
  cursor: not-allowed;
}

.mint-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.edit-button {
  border-radius: 50%;
  cursor: pointer;
  margin-left: 3px;
  top: 20px;
  border: 0px;
  font-size: 12px;
  background-color: transparent;
}

.edit-icon {
  width: 12px;
}

.w3 {
  color: #03CF9E;
}

.safe {
  font-size: x-small;
}

.table-ppl[data-v-0948da16] {
  border: 2px solid white;
  border-radius: 15px !important;
  border-collapse: separate;
  color: #fff;
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #303030;
  --bs-table-striped-bg: #303030;
  --bs-table-active-color: #303030;
  --bs-table-active-bg: #303030;
  --bs-table-hover-color: #303030;
  --bs-table-hover-bg: #303030;
  width: 100%;
  margin-bottom: 1rem;
  color: #303030;
  vertical-align: top;
  border-color: #ffffff;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: #ffffff;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.twitter-logo {
  width: 35px;
  height: 35px;
}

.w3up-logo {
  width: 85px;
  height: 85px;
  margin-right: 30px;
  margin-bottom: 15px;
  align-items: center;
}

.wlogo {
  width: 165px;
}

.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-bottom: 15px;
  align-items: center;
}

@keyframes floating {
  from {
    transform: translate(0, 0px);
  }

  65% {
    transform: translate(0, 12px);
  }

  to {
    transform: translate(0, -0px);
  }
}

.footer-text {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #03CF9E;
  color: #000000;
  text-align: center;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #03CF9E;
  color: #000000;
  text-align: center;
}

a {
  color: #000000;
}

p a {
  color: inherit;
}

.form-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

input {
  border: 0;
  border-radius: 15px;
  background-color: #303030;
  color: white;
  letter-spacing: 1px;
  font-size: 26px;
  width: 400px;
  margin-bottom: 10px;
  padding: 10px 20px;
  text-align: center;
}

input::placeholder {
  color: gray;
  letter-spacing: 2px;
}

input:focus {
  outline: none;
}

.first-row {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

}

.tld {
  position: absolute;
  font-size: 20px;
  font-weight: bold;
  color: white;
  right: 22px;
  margin: 0;
  padding: 0;
  margin-bottom: 13px;
}

.mint-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.mint-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-radius: 15px;
  margin: 10px 15px;
  font-size: 18px;
  background-color: #c4b7fa;
  color: black;
  min-width: 150px;
  max-width: fit-content;
  box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 0.2);
}

/* Different background-colour for every nth mint-item */
.mint-item:nth-child(2n) {
  background-color: lightblue;
}

.mint-item:nth-child(3n) {
  background-color: lightpink;
}

.mint-item:nth-child(5n) {
  background-color: lightgreen;
}

.link {
  color: black;
  text-decoration: none;
}

.underlined {
  text-decoration: solid;
  color: white;
}

.underlined:hover {
  cursor: pointer;
}

/* KeyFrames */
@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}